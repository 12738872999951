import dayjs from "dayjs"

const disableWeekends = (date: dayjs.Dayjs): boolean => {
  const day = date.day()
  return day === 0 || day === 6
}

export const getEarliestPickupDate = (NumberOfDisabledDays = 4): dayjs.Dayjs => {
  let earliestPickupDate = dayjs()
  let workingDaysCount = 0

  while (workingDaysCount < NumberOfDisabledDays) {
    earliestPickupDate = earliestPickupDate.add(1, "day")
    if (!disableWeekends(earliestPickupDate)) {
      workingDaysCount++
    }
  }

  return earliestPickupDate
}

export const disableDates =
  (earliestPickupDate: dayjs.Dayjs) =>
  (date: dayjs.Dayjs): boolean => {
    return date.isBefore(earliestPickupDate, "day") || disableWeekends(date)
  }
