import { OrderListFragment, OrderStatus } from "@gen/graphql"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
} from "@mui/material"
import { Button, Card, CardContent, Grid, TextField } from "@northvolt/ui"
import { IconSearch, sumOrderGrossWeight, sumOrderNetWeight } from "@shared"
import { TFunction } from "i18next"
import { JSX, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

type Filter = (orders: OrderListFragment[]) => OrderListFragment[]

const filterOnDates = (selected: string[], t: TFunction<"common", undefined>): Filter => {
  return (orders: OrderListFragment[]): OrderListFragment[] => {
    if (selected.length === 0) {
      return orders
    }
    return orders.filter(
      (order) =>
        selected.includes(order.pickupDate ?? t("components.FilterPanel.undefined")) ||
        (!order.pickupDate && selected.includes(t("components.FilterPanel.undefined"))),
    )
  }
}

const filterOnLocations = (selected: string[], t: TFunction<"common", undefined>): Filter => {
  return (orders: OrderListFragment[]): OrderListFragment[] => {
    if (selected.length === 0) {
      return orders
    }
    return orders.filter(
      (order) =>
        selected.includes(
          order.pickupLocation?.displayName ?? t("components.FilterPanel.undefined"),
        ) ||
        (order.pickupLocation?.displayName === undefined &&
          selected.includes(t("components.FilterPanel.undefined"))),
    )
  }
}

const filterOnStatus = (selected: string[]): Filter => {
  return (orders: OrderListFragment[]): OrderListFragment[] => {
    if (selected.length === 0) {
      return orders
    }
    return orders.filter((order) => selected.includes(order.status))
  }
}

const filterOnSearch = (query: string, t: TFunction<"common", undefined>): Filter => {
  return (orders: OrderListFragment[]): OrderListFragment[] => {
    if (query === "") {
      return orders
    }
    return orders.filter((order) => {
      const name = order.createdBy?.firstName + " " + order.createdBy?.lastName
      return (
        query !== "" &&
        (sumOrderNetWeight(order).toString().includes(query) ||
          sumOrderGrossWeight(order).toString().includes(query) ||
          `${order.boxes.length.toString()} ${t("components.FilterPanel.packages")}`
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) ||
          order.pickupLocation?.displayName
            ?.toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()) ||
          order.status.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
          order.pickupDate?.includes(query) ||
          (!!order.orderID &&
            order.orderID.toLocaleLowerCase().includes(query.toLocaleLowerCase())) ||
          name.toLocaleLowerCase().includes(query.toLocaleLowerCase()))
      )
    })
  }
}

const applyFilters = (orders: OrderListFragment[], filters: Filter[]): OrderListFragment[] => {
  return filters.reduce((acc, filter) => filter(acc), orders)
}

type FilterPanelProps = {
  orders: OrderListFragment[]
  onFilterChange: (filteredOrders: OrderListFragment[]) => void
}

export const FilterPanel = ({ orders, onFilterChange }: FilterPanelProps): JSX.Element => {
  const { t } = useTranslation()

  const [selectedPickupDates, setSelectedPickupDates] = useState<string[]>([])
  const uniquePickupDates: string[] = Array.from(
    new Set(
      orders.map((order) => {
        if (!order.pickupDate) {
          return t("components.FilterPanel.undefined")
        }
        return order.pickupDate
      }),
    ),
  )
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const uniqueLocations: string[] = Array.from(
    new Set(
      orders.map((order) => {
        if (!order.pickupLocation) {
          return t("components.FilterPanel.undefined")
        }
        return order.pickupLocation.displayName
      }),
    ),
  )
  const uniqueOrderStatuses = Array.from(new Set(orders.map((order) => order.status)))
  const [selectedStatuses, setSelectedStatuses] = useState<OrderStatus[]>([])
  const [searchQuery, setSearchQuery] = useState<string>("")

  const filteredOrders = useMemo(() => {
    return applyFilters(orders, [
      filterOnLocations(selectedLocations, t),
      filterOnDates(selectedPickupDates, t),
      filterOnStatus(selectedStatuses),
      filterOnSearch(searchQuery, t),
    ])
  }, [orders, selectedLocations, selectedPickupDates, selectedStatuses, searchQuery, t])

  useEffect(() => {
    onFilterChange(filteredOrders)
  }, [filteredOrders, onFilterChange])

  const clearFilters = (): void => {
    setSelectedPickupDates([])
    setSelectedLocations([])
    setSelectedStatuses([])
    setSearchQuery("")

    onFilterChange(orders)
  }

  const filterButtonDisplay =
    selectedLocations.length === 0 &&
    selectedPickupDates.length === 0 &&
    selectedStatuses.length === 0 &&
    searchQuery === ""
      ? "none"
      : "block"

  return (
    <Card sx={{ pt: 2, borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>{t("components.FilterPanel.location")}</InputLabel>
              <MuiSelect
                multiple
                input={<OutlinedInput label={t("components.FilterPanel.location")} />}
                value={selectedLocations}
                onChange={(event) => setSelectedLocations(event.target.value as string[])}
                renderValue={(selected) => selected.join(", ")}
              >
                {uniqueLocations.map((location, index) => (
                  <MenuItem key={`location-${index}`} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>{t("components.FilterPanel.date")}</InputLabel>
              <MuiSelect
                multiple
                input={<OutlinedInput label={t("components.FilterPanel.date")} />}
                value={selectedPickupDates}
                onChange={(event) => setSelectedPickupDates(event.target.value as string[])}
                renderValue={(selected) => selected.join(", ")}
              >
                {uniquePickupDates.map((date, index) => (
                  <MenuItem key={`date-${index}`} value={date}>
                    {date}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>{t("components.FilterPanel.status")} </InputLabel>
              <MuiSelect
                multiple
                input={<OutlinedInput label={t("components.FilterPanel.status")} />}
                value={selectedStatuses}
                onChange={(event) => setSelectedStatuses(event.target.value as OrderStatus[])}
                renderValue={(selected) => selected.join(", ")}
              >
                {uniqueOrderStatuses.map((option, index) => (
                  <MenuItem key={`status-${index}`} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <TextField
                label={t("components.FilterPanel.search.label")}
                onChange={(event) => setSearchQuery(event.target.value)}
                InputProps={{
                  endAdornment: <IconSearch />,
                }}
                value={searchQuery}
                placeholder={t("components.FilterPanel.search.placeholder")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid justifyContent="flex-start">
          <Button
            color="secondary"
            variant="outlined"
            sx={{ mt: 2, display: filterButtonDisplay }}
            onClick={clearFilters}
          >
            {t("components.FilterPanel.clearFilters")}
          </Button>
        </Grid>
      </CardContent>
    </Card>
  )
}
