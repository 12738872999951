import {
  OrderFragment,
  useAddLocationAndDateMutation,
  usePickupOrderLocationsQuery,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { Typography } from "@mui/material"
import { Card, CardContent, useSnackbar } from "@northvolt/ui"
import {
  CancelButton,
  CardActions,
  LocationAndDateFormSchema,
  LocationAndDateInput,
  NextButton,
} from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { type Dayjs } from "dayjs"
import { JSX } from "react"
import { DefaultValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { Contacts } from "../Contacts/Contacts"

type InputTypes = z.infer<typeof LocationAndDateFormSchema>

type LocationAndDateProps = {
  order: OrderFragment
  stepId: string
}

export const LocationAndDate = ({ order, stepId }: LocationAndDateProps): JSX.Element => {
  const { data, error } = usePickupOrderLocationsQuery({ variables: {} })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateSnackbar = useSnackbar()

  const defaultValues: Required<DefaultValues<InputTypes>> = {
    location: order.pickupLocation?.id || "",
    date: undefined as unknown as Dayjs,
  }

  const [addLocationAndDate] = useAddLocationAndDateMutation()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<InputTypes>({
    resolver: zodResolver(LocationAndDateFormSchema),
    defaultValues,
  })

  const handleClick = (): void => {
    if (!isValid) {
      updateSnackbar({
        message: t("components.LocationAndDate.dateTooEarly"),
        severity: "error",
      })
    }
  }

  const onSubmit: SubmitHandler<InputTypes> = (submitData) => {
    addLocationAndDate({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          locationId: submitData.location,
          date: submitData.date.format("YYYY-MM-DD"),
        },
      },
    }).then((res) => {
      if (!res.data?.addLocationAndDate?.id) {
        updateSnackbar({
          message: t("components.LocationAndDate.addFail"),
          severity: "error",
        })
      } else {
        updateSnackbar({
          message: t("components.LocationAndDate.addSuccess"),
          severity: "success",
        })
        navigate({
          to: "/pickup-orders/$orderId/$stepId",
          params: { orderId: res.data.addLocationAndDate.id, stepId: `${parseInt(stepId) + 1}` },
        })
      }
    })
  }

  if (error) throw new Error("Error loading pickup order locations")

  if (!data) return <div>{t("components.basics.loading")}</div>

  const locations =
    data.locations.map<{ label: string; value: string }>((location) => ({
      label: location.displayName,
      value: location.id,
    })) ?? []
  return (
    <Card>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Typography variant="headlineSmall" mb={2}>
            {t("components.LocationAndDate.title")}
          </Typography>
          <LocationAndDateInput locations={locations} control={control} errors={errors} />
          <Contacts order={order} />
        </CardContent>
        <CardActions>
          <CancelButton onClick={() => navigate({ to: "/pickup-orders" })} />
          <div onClick={handleClick}>
            <NextButton isValid={isValid} />
          </div>
        </CardActions>
      </form>
    </Card>
  )
}
