import { PackSummary } from "@components/PickupOrder/Components/PackSummary/PackSummary"
import { BoxFragment, OrderFragment, OrderStatus, useDeleteGroupMutation } from "@gen/graphql"
import { styled } from "@mui/material/styles"
import { Box, Card, IconButton, Typography } from "@northvolt/ui"
import { ConfirmationDialog, IconArrowRight, IconBoxesStacked, IconTrashCan } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"
import { useTranslation } from "react-i18next"

type PackProps = {
  stepId: string
  order: OrderFragment
  boxes: BoxFragment[]
  editable: boolean
  selectedId?: string
}

export const PackGroupSummary = ({
  stepId,
  order,
  boxes,
  editable,
  selectedId,
}: PackProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [deleteGroup] = useDeleteGroupMutation()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handleDelete = (): void => {
    deleteGroup({
      variables: {
        input: {
          etag: order.etag,
          pickupOrderId: order.id,
          groupId: boxes[0].group,
        },
      },
    })
    setOpenDeleteDialog(false)
  }

  const disableEdit = order.status === OrderStatus.AwaitingApproval

  if (boxes.length === 1)
    return (
      <PackSummary order={order} orderBox={boxes[0]} editable={editable} selectedId={selectedId} />
    )

  return (
    <>
      <PackCard variant="outlined" sx={{ padding: 1 }}>
        <Box sx={{ p: 1 }}>
          <IconBoxesStacked />
        </Box>
        <Box flexGrow={1}>
          <Typography>{t("components.PackGroupSummary.title")}</Typography>
          <Typography>
            {t("components.PackGroupSummary.packages", { packs: boxes.length })}
          </Typography>
        </Box>
        {editable && (
          <Box sx={{ p: 1 }}>
            <IconButton
              size="small"
              onClick={() => {
                navigate({
                  to: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit",
                  params: {
                    stepId: stepId,
                    orderId: order.id,
                    groupId: boxes[0].group as string,
                    packageId: boxes[0].id,
                  },
                })
              }}
              disabled={disableEdit}
            >
              <IconArrowRight />
            </IconButton>
          </Box>
        )}
        {editable && (
          <Box>
            <IconButton
              data-testid="pack-group-summary-delete-button"
              size="small"
              onClick={() => setOpenDeleteDialog(true)}
              disabled={disableEdit}
            >
              <IconTrashCan />
            </IconButton>
          </Box>
        )}
      </PackCard>
      <ConfirmationDialog
        open={openDeleteDialog}
        title={t("components.ConfirmationDialog.title.deletePackage")}
        confirmButtonText={t("components.ConfirmationDialog.confirm")}
        cancelButtonText={t("components.ConfirmationDialog.cancel")}
        onConfirm={handleDelete}
        onCancel={() => setOpenDeleteDialog(false)}
      ></ConfirmationDialog>
    </>
  )
}

const PackCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
