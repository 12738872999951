import { Typography } from "@mui/material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@northvolt/ui"
import { JSX } from "react"
import { useState } from "react"
import { useTranslation } from "react-i18next"

type ConfirmationDialogProps = {
  open: boolean
  title: string
  message?: string
  onConfirm: () => void
  onCancel: () => void
  confirmButtonText: string
  cancelButtonText: string
  confirmButtonColor?:
    | "primary"
    | "error"
    | "secondary"
    | "inherit"
    | "success"
    | "info"
    | "warning"
  cancelButtonColor?: "primary" | "error" | "secondary" | "inherit" | "success" | "info" | "warning"
}

export const ConfirmationDialog = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = "secondary",
  cancelButtonColor = "secondary",
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <Dialog
      data-testid={"confirmation-dialog"}
      open={open}
      onClose={onCancel}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle style={{ minWidth: "444px" }} id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>
        <DialogContentText id="dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", padding: "16px 24px" }}>
        <Button onClick={onCancel} color={cancelButtonColor} variant="text" autoFocus>
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirm} color={confirmButtonColor}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type ConfirmationDialogWithTextfieldProps = {
  orderID: string
  open: boolean
  title: string
  message?: string
  textFieldLabel: string
  onConfirm: (textFieldValue: string, orderID: string) => void
  onCancel: () => void
  confirmButtonText: string
  cancelButtonText: string
  confirmButtonColor?:
    | "primary"
    | "error"
    | "secondary"
    | "inherit"
    | "success"
    | "info"
    | "warning"
  cancelButtonColor?: "primary" | "error" | "secondary" | "inherit" | "success" | "info" | "warning"
}

export const ConfirmationDialogWithTextfield = ({
  orderID,
  open,
  title,
  message,
  textFieldLabel,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor = "secondary",
  cancelButtonColor = "secondary",
}: ConfirmationDialogWithTextfieldProps): JSX.Element => {
  const [textFieldValue, setTextFieldValue] = useState("")

  const handleConfirm = async (): Promise<void> => {
    onConfirm(textFieldValue, orderID)
  }

  return (
    <Dialog
      data-testid={"confirmation-dialog"}
      open={open}
      onClose={onCancel}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle style={{ minWidth: "444px" }} id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>
        <TextField
          label={textFieldLabel}
          variant="outlined"
          fullWidth
          sx={{ mt: 1 }}
          value={textFieldValue}
          onChange={(e) => setTextFieldValue(e.target.value)}
          data-testid="confirmation-dialog-input"
        />
        <DialogContentText id="dialog-description" sx={{ ml: 1 }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", padding: "16px 24px" }}>
        <Button onClick={onCancel} color={cancelButtonColor} variant="text" autoFocus>
          {cancelButtonText}
        </Button>
        <Button onClick={handleConfirm} color={confirmButtonColor}>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const StatusInfoDialog = ({
  open,
  info,
  onCancel,
}: {
  open: boolean
  info: JSX.Element
  onCancel: () => void
}): JSX.Element => {
  const { t } = useTranslation("components")
  return (
    <Dialog maxWidth="sm" data-testid={"status-info-dialog"} open={open} onClose={onCancel}>
      <DialogTitle id="dialog-title">
        <Typography variant="headlineMedium">{t("StatusInfoDialog.title")}</Typography>
        <Typography variant="bodyMedium">{t("StatusInfoDialog.subtitle")}</Typography>
      </DialogTitle>
      <DialogContent style={{ justifyContent: "flex-start" }}>{info}</DialogContent>
      <DialogActions style={{ justifyContent: "flex-end", padding: "16px 24px" }}>
        <Button color="secondary" onClick={onCancel}>
          {t("StatusInfoDialog.button")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
