import { Alert, MenuItem } from "@mui/material"
import { Stack } from "@mui/system"
import { FormSelect } from "@northvolt/ui/Form"
import dayjs, { type Dayjs } from "dayjs"
import { JSX } from "react"
import { Control, FieldErrors } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { disableDates, getEarliestPickupDate } from "../../lib/date"
import { Date } from "../Date/Date"

const earliestPickupDate = getEarliestPickupDate()

export const LocationAndDateFormSchema = z.object({
  location: z.string().uuid(),
  date: z
    .instanceof(dayjs as unknown as typeof Dayjs)
    .refine((date) => !disableDates(earliestPickupDate)(date)),
})

export type InputTypes = z.infer<typeof LocationAndDateFormSchema>

type Locations = {
  label: string
  value: string
}[]

type LocationAndDateInputProps = {
  locations: Locations
  control: Control<InputTypes>
  errors: FieldErrors<InputTypes>
}

export const LocationAndDateInput = ({
  locations,
  control,
  errors,
}: LocationAndDateInputProps): JSX.Element => {
  const { t } = useTranslation("components")
  const shortEditTime = earliestPickupDate.isSame(earliestPickupDate, "day")

  return (
    <Stack spacing={1}>
      {locations && (
        <FormSelect
          control={control}
          fullWidth
          name="location"
          label={t("LocationAndDateInput.selectLocation.label")}
          labelId="location-select-label"
          sx={{
            minWidth: "300px",
          }}
        >
          {locations.map((l) => (
            <MenuItem key={l.value} value={l.value}>
              {l.label}
            </MenuItem>
          ))}
        </FormSelect>
      )}

      <Date control={control} name="date" earliestPickupDate={earliestPickupDate} />
      {shortEditTime && <Alert severity="info">{t("LocationAndDateInput.shortEditTime")}</Alert>}

      {errors.location && (
        <Alert sx={{ bgcolor: "inherit", alignSelf: "center" }} severity="error">
          {errors.location.message}
        </Alert>
      )}
      {errors.date && (
        <Alert sx={{ bgcolor: "inherit", alignSelf: "center" }} severity="error">
          {errors.date.message}
        </Alert>
      )}
    </Stack>
  )
}
