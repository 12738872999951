import { DatePickerDayjs } from "@northvolt/ui/Dayjs"
import dayjs from "dayjs"
import { JSX } from "react"
import { Control, FieldPath, FieldValues, useController } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { disableDates } from "../../lib/date"

export const Date = <T extends FieldValues, U extends FieldPath<T>>({
  control,
  name,
  earliestPickupDate = dayjs(),
}: {
  control: Control<T>
  name: U
  earliestPickupDate?: dayjs.Dayjs
}): JSX.Element => {
  const { t } = useTranslation("components")
  const {
    field: { ref, onChange, value, disabled },
    fieldState: { error },
  } = useController({ control, name })

  return (
    <>
      <DatePickerDayjs
        format="YYYY-MM-DD"
        ref={ref}
        value={value || null}
        name={name}
        disabled={disabled}
        onChange={onChange}
        shouldDisableDate={disableDates(earliestPickupDate)}
        slotProps={{
          textField: { label: t("Date.placeholder") },
        }}
      />
      {error && <div>{error.message}</div>}
    </>
  )
}
