import { ModuleGroupSummary } from "@components/PickupOrder/Components/ModuleGroupSummary/ModuleGroupSummary"
import { PackGroupSummary } from "@components/PickupOrder/Components/PackGroupSummary/PackGroupSummary"
import { BoxFragment, OrderFragment } from "@gen/graphql"
import { Stack } from "@northvolt/ui"
import { JSX } from "react"

type ListOrderBoxesProps = {
  stepId: string
  order: OrderFragment
  editable: boolean
  selectedId?: string
}

export const ListOrderBoxes = ({
  stepId,
  order,
  editable,
  selectedId,
}: ListOrderBoxesProps): JSX.Element => {
  const groupPacks = new Map<string, BoxFragment[]>()
  order?.boxes.forEach((p) => {
    const group = groupPacks.get(p.group)
    if (group) {
      group.push(p)
    } else {
      groupPacks.set(p.group, [p])
    }
  })

  return (
    <Stack gap={2}>
      {order &&
        groupPacks.size > 0 &&
        Array.from(groupPacks.values()).map((group) =>
          group[0].items[0]?.__typename === "Pack" ? (
            <PackGroupSummary
              key={group[0].group}
              stepId={stepId}
              order={order}
              boxes={group}
              editable={editable}
              selectedId={selectedId}
            />
          ) : (
            <ModuleGroupSummary
              key={group[0].group}
              stepId={stepId}
              order={order}
              boxes={group}
              editable={editable}
              selectedId={selectedId}
            />
          ),
        )}
    </Stack>
  )
}
